import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import { useTranslation } from "@hooks"
import * as ROUTES from "@constants/routes"

import Button from "react-bulma-components/lib/components/button"
import Heading from "react-bulma-components/lib/components/heading"
import Content from "react-bulma-components/lib/components/content"

const Styles = ({ styles }) => {
  const [t] = useTranslation()

  const navigateToStyle = style => {
    // Update `redux`?
    navigate(`${ROUTES.TATTOOS}/?styles=${style}`)
  }

  return (
    <Content>
      <Heading renderAs={"h3"} subtitle size={5}>
        {t("components.Styles.heading")}
      </Heading>
      <Button.Group>
        {styles
          ? styles.map((style, i) => (
              <Button
                key={i}
                renderAs="span"
                color="light"
                rounded
                onClick={() => navigateToStyle(style)}
              >
                {style}
              </Button>
            ))
          : null}
      </Button.Group>
    </Content>
  )
}

Styles.propTypes = {
  styles: PropTypes.array,
}

export default Styles
