import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Image from "react-bulma-components/lib/components/image"
import Columns from "react-bulma-components/lib/components/columns"

const PortfolioItem = ({ photo }) => {
  return (
    <Columns.Column
      mobile={{
        size: 4,
      }}
      tablet={{
        size: 4,
      }}
      desktop={{
        size: 3,
      }}
      widescreen={{
        size: 3,
      }}
      fullhd={{
        size: 3,
      }}
    >
      <Link to={`/tattoos/${photo.id}/`}>
        <Image
          src={photo.photoUrl}
          size={"square"}
          className="no-stretch"
          loading={"lazy"}
        />
      </Link>
    </Columns.Column>
  )
}

PortfolioItem.propTypes = {
  photo: PropTypes.object,
}

export default PortfolioItem
