import React from "react"
import PropTypes from "prop-types"
import { InstantSearch, connectHits, Configure } from "react-instantsearch-dom"

import PortfolioItem from "@components/PortfolioItem"
import NoResults from "@components/NoResults"

import { useAlgolia, useTranslation } from "@hooks"
import { PHOTO_FLASH } from "@constants"

import Box from "react-bulma-components/lib/components/box"
import Columns from "react-bulma-components/lib/components/columns"
import Heading from "react-bulma-components/lib/components/heading"
import Content from "react-bulma-components/lib/components/content"

const Hits = ({ hits }) => {
  return (
    <Columns breakpoint="mobile" gapless>
      {!hits.length ? (
        <NoResults />
      ) : (
        hits.map((hit, i) => <PortfolioItem key={i} photo={hit} />)
      )}
    </Columns>
  )
}

const CustomHits = connectHits(Hits)

CustomHits.defaultProps = {
  hits: [],
}

CustomHits.propTypes = {
  hits: PropTypes.array.isRequired,
}

const Flash = ({ user, filters }) => {
  const [t] = useTranslation()

  const searchClient = useAlgolia()

  let _filters = `type:${PHOTO_FLASH} AND user.id:${user.id}`
  if (filters) _filters += filters
  return (
    <Box>
      <Content>
        <Heading subtitle size={5}>
          {t("components.Flash.heading")}
        </Heading>
      </Content>
      <InstantSearch indexName={"photos"} searchClient={searchClient}>
        <Configure filters={_filters} hitsPerPage={9} />
        <CustomHits />
      </InstantSearch>
    </Box>
  )
}

Flash.propTypes = {
  user: PropTypes.object,
  filters: PropTypes.string,
}

export default Flash
